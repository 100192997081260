import React from 'react';
import Helmet from 'react-helmet';
import '../../assets/css/App.css';
import '../../assets/css/index.css';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

export default () => (
  <Layout>
    <SEO
      title="Blog"
      description="BBIS blog - Helping you with knowledgeable information and advice on all commercial heating needs"
      keywords="commercial heating blog, domestic heating blog,"
    />
    <section class="text-left container container-blog my-4">
      <h1 class="heading-color">Greener ways to heat your premises</h1>
      <p>
        We are all trying to do what we can to help tackle the current climate
        issues we are now facing. We know how important it is to make sure we
        recycle where possible, and use greener products which have a less
        damaging ozone layer effect. The use of electric cars is slowly becoming
        more popular as we now know that the diesel and petrol fumes are causing
        air pollution which is a risk to our health and the environment.
      </p>
      <p>
        So of course, it is a natural progression for the heating sector to find
        alternative ways to produce heat which are kinder to the environment.
        The boiler manufactures are producing the most economical and energy
        efficient boilers, which will be cheaper for you to run and will have
        less fuel wastage as possible.
      </p>
      <p>
        The government are now looking to dramatically reduce the carbon
        emissions caused by burning fossil fuels over the foreseeable future.
        There is a government scheme (RHI) renewable heat incentive that can
        help you with costs of installing a more environmentally friendly
        heating system.
      </p>
      <p>
        We talk about the use of Air source heat pumps, ground source heat pumps
        and Biomass boilers on our website. All these alternatives produce zero
        or minimal carbon emissions.
      </p>
      <p>
        Baxi boilers are also working on a prototype of a boiler which will run
        on 100% hydrogen, and produces zero carbon emissions, the only
        by-product will be water.
      </p>
      <p>
        Research on the hydrogen supply system is still in the very early
        stages, there are trials being carried out at the moment working on 80%
        natural gas with 20% hydrogen on a private gas network.
      </p>
      <p>
        Just switching to a new condensing boiler will also have a positive
        impact on the environment, as they recycle heat from the burning process
        to preheat the water entering the boiler. They are much more practical
        and energy efficient.
      </p>
      <p>
        For any advice on renewable energy please do not hesitate to get in
        touch.
      </p>
    </section>
  </Layout>
);
